import { css } from '@emotion/react';

export const root = css`
  h1,
  h2 {
    width: 50%;
    color: white;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: calc(var(--unit) * 20);
    font-family: var(--font-family-secondary);
    font-weight: normal;
  }

  h2 {
    font-weight: normal;
  }
`;

export const alert = css`
  margin: calc(var(--unit) * 5) auto;
  width: 50%;
`;

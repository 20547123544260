import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import { Alert, Logo } from 'modules/app';
import { EmailVerifying } from './EmailVerifying';
import { LinkExpired } from './LinkExpired';

import { FormattedMessage } from 'react-intl';
import { LinkExecuted } from './LinkExecuted';
import Seo from 'modules/app/components/Seo';

import * as formStyles from './styles/LinkExpired.styles';
import * as rootStyles from './styles/Root.styles';
import { useVerifyUser } from '../hooks';

export const Root: React.FC<RouteComponentProps> = () => {
  const params = useParams();

  const { formatMessage } = useIntl();

  const { isInVerifyRequest, cause, isOtherError, isSuccess } = useVerifyUser(
    params.token,
  );

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.register' })} />

      <div css={rootStyles.root}>
        <Logo css={rootStyles.logo} />

        <div css={rootStyles.component}>
          {!cause && !isOtherError && (
            <EmailVerifying
              isSuccess={isSuccess}
              isInRequest={isInVerifyRequest}
            />
          )}

          {cause === 'token_action_expired' && <LinkExpired />}

          {cause === 'not_found' && (
            <Alert
              styles={formStyles.alert}
              message={
                <FormattedMessage id="shared.verification.token_not_found" />
              }
              type="error"
              show={true}
            />
          )}

          {cause === 'token_action_executed' && <LinkExecuted />}

          <Alert
            styles={formStyles.alert}
            message={<FormattedMessage id="http_errors.generic_error" />}
            type="error"
            show={isOtherError}
          />
        </div>
      </div>
    </>
  );
};

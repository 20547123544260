import React, { useState } from 'react';
import {
  AccountQuery,
  BaseCookieConsent,
  ContentWithDeco,
  CookieConsent,
  Grid,
  Heading,
  useCookiePolicy,
} from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { AuthBackground } from 'modules/app/components/stepMechanism';

import * as styles from 'modules/register/styles/Steps.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { GhostRegisterForm } from './forms/GhostRegisterForm';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';
import { CookieForm } from './steps/CookieForm';

export const GhostRegistrationRoot: React.FC = () => {
  const { getCookie } = useCookiePolicy();
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    getCookie() === 'accept' || getCookie() === 'necessary',
  );
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.register' })} />

      <BaseCookieConsent>
        <CookieConsent onAction={setIsCookieAccepted} />
      </BaseCookieConsent>

      <Grid type="pageTwoCols">
        <AuthBackground step="step-one" type="register" />

        {isCookieAccepted ? (
          <ContentWithDeco css={styles.root}>
            <div css={styles.accountLink}>
              <AccountQuery type="register" />
            </div>
            <motion.div
              transition={{ delay: 0.1, duration: 0.4 }}
              style={{ opacity: 0 }}
              {...FADE_IN_MOVE_Y_REGULAR}
            >
              <Heading css={styles.titleShort} as="h1" size="fluidXxLarge">
                <FormattedMessage id="register.page.create_account_title" />
              </Heading>

              <Heading css={styles.subtitle} as="h2" size="root">
                <FormattedMessage id="register.page.create_account_subtitle" />
              </Heading>

              <GhostRegisterForm />
            </motion.div>
          </ContentWithDeco>
        ) : (
          <ContentWithDeco css={styles.root}>
            <div css={styles.accountLink}>
              <AccountQuery type="register" />
            </div>

            <CookieForm setIsCookieAccepted={setIsCookieAccepted} />
          </ContentWithDeco>
        )}
      </Grid>
    </>
  );
};

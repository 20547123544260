import React from 'react';
import * as styles from 'modules/onboarding/styles/Header.styles';
import { Breadcrumbs } from './Breadcrumbs';

interface Props {
  step: OnboardingStep;
}

export const Header: React.FC<Props> = ({ step }) => {
  const steps: OnboardingStep[] = [
    'talent',
    'basics',
    'education/certificates',
    'expertise',
  ];

  return (
    <div css={styles.root}>
      <Breadcrumbs step={step} steps={steps} />
    </div>
  );
};

import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { Button } from 'modules/app';
import React from 'react';

import * as styles from 'modules/onboarding/styles/MobileReaction.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useDispatch } from 'react-redux';
import { talentOnboardingAction } from 'modules/onboarding/redux';

export const MobileReaction: React.FC = () => {
  const dispatch = useDispatch();

  function onFinish() {
    dispatch(
      talentOnboardingAction.updateTalentOnboardingAccess({
        finishedScreen: true,
      }),
    );
    navigate('/app/onboarding-talent-final');
  }

  return (
    <div css={styles.root}>
      <motion.div {...FADE_IN_MOVE_Y_REGULAR} transition={{ duration: 0.4 }}>
        <div css={styles.buttons}>
          <Button buttonType="secondary" onClick={onFinish}>
            <FormattedMessage id="onboarding.expertise.btn" />
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

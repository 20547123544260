import React from 'react';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { FormattedMessage } from 'react-intl';

import { ContentWithDeco, Heading } from 'modules/app';
import { FormData, FormOne } from '../forms';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import * as styles from 'modules/register/styles/Steps.styles';
import * as rootStyles from '../styles/Root.styles';
import { useResetPassword } from 'modules/forgotPassword/hooks';

export const StepOne: React.FC<StepRouterComponent> = ({ onChangeStep }) => {
  const { isLoading, error, resetPassword } = useResetPassword();

  const params = useParams();

  function onReset() {
    onChangeStep('step-two');
    navigate('/app/reset-password/complete');
  }

  const onValid = async (data: FormData) => {
    if (!params || typeof params.token !== 'string') return;
    resetPassword(params.token, data.password, onReset);
  };

  return (
    <ContentWithDeco css={[styles.root, rootStyles.content]}>
      <Heading css={rootStyles.heading} as="h1" size="xxLarge">
        <FormattedMessage id="reset_password.page.title" />
      </Heading>

      <FormOne httpError={error} onValid={onValid} isInRequest={isLoading} />
    </ContentWithDeco>
  );
};

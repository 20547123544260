import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';

export const root = css`
  width: 100%;
  max-width: ${spacing(528)};
`;

export const paragraphSeparator = css`
  margin: calc(var(--unit) * 10) 0 calc(var(--unit) * 10);
  max-width: ${spacing(416)};

  @media ${breakpoints.large} {
    margin: calc(var(--unit) * 20) 0 calc(var(--unit) * 10);
  }
`;

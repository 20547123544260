import { css } from '@emotion/react';

export const formButtonWrapper = css`
  display: flex;
  justify-content: space-between;

  a {
    font-family: var(--font-family-primaryBold);
    font-weight: 700;
    color: hsl(var(--color-text-1));
    margin-top: calc(var(--unit) * 8);
    text-decoration: underline;

    :hover {
      border-bottom: 1px solid hsl(var(--color-primary-1));
    }
  }
`;

export const alertFix = css`
  margin-bottom: calc(var(--unit) * 10);
`;

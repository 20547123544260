import { RouteComponentProps } from '@reach/router';
import { Heading, HttpError, LoadingImg, Logo, MainContent } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHandlePlan } from '../hooks';
import { billingActions } from '../redux';

import * as styles from 'modules/billing/styles/PaymentCheck.styles';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';

interface Props extends RouteComponentProps {
  companyId?: string;
}

export const CardCheck: React.FC<Props> = ({ companyId }) => {
  const dispatch = useDispatch();
  const companyRoles = useSelector(
    (state: AppState) => state.session.userCompanyRoles,
  );
  const { isLoading, error, paymentCheck } = useSelector(
    (state: AppState) => state.billing,
  );
  const currentRole = companyRoles?.find(
    (role) => role.companyId === companyId,
  );
  const { checkCard, getQueryParams } = useHandlePlan();
  const { transactionId } = getQueryParams();

  useEffect(() => {
    if (!companyId || !transactionId) return;

    checkCard(companyId, transactionId);

    return () => {
      dispatch(billingActions.setPaymentCheck(undefined));
    };
  }, []);

  return (
    <>
      <Seo title="Card Processing" />

      <section css={styles.root}>
        <MainContent customStyles={styles.wrapper}>
          <Logo />
          <div css={styles.content}>
            {error ? (
              <HttpError error={error} />
            ) : isLoading || !transactionId ? (
              <div css={styles.loading}>
                <LoadingImg />
                <Heading as="h3" size="fluidXxxLarge">
                  <FormattedMessage id="settings.billingContent.paymentMethod.processing" />
                </Heading>
              </div>
            ) : (
              <div>
                <Heading as="h3" size="fluidXxxLarge">
                  <FormattedMessage
                    id={
                      paymentCheck?.status === 'Success'
                        ? 'settings.billingContent.paymentMethod.cardSuccess'
                        : 'settings.billingContent.paymentMethod.cardFail'
                    }
                  />
                </Heading>

                <Link
                  to={`/profile/settings/company/${currentRole?.handle}/billing/payment-method`}
                  css={styles.link}
                >
                  <FormattedMessage
                    id={
                      paymentCheck?.status === 'Success'
                        ? 'settings.billingContent.paymentMethod.cardSuccessBtn'
                        : 'settings.billingContent.paymentMethod.errorBtn'
                    }
                  />
                </Link>
              </div>
            )}
          </div>
        </MainContent>
      </section>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import * as styles from 'modules/onboarding/styles/Step.styles';
import * as stepStyles from 'modules/onboarding/styles/StepRoot.styles';
import * as reactionStyles from 'modules/onboarding/styles/DynamicReaction.styles';
import {
  MobileReaction,
  TalentReaction,
  Header,
} from 'modules/onboarding/components';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';

interface Props extends RouteComponentProps {
  header: React.ReactNode;
  component: React.ReactNode;
  reaction?: React.ReactNode;
  isInRequest?: boolean;
}

export const Step: React.FC<Props> = ({ header, component }) => {
  const [currentStep, setCurrentStep] = useState<OnboardingStep>('basics');

  useEffect(() => {
    setCurrentStep('expertise');
  }, []);

  return (
    <div css={stepStyles.root}>
      <div css={reactionStyles.root}>
        <TalentReaction />
      </div>
      <div css={stepStyles.formsContent}>
        <Header step={currentStep} />

        <motion.div
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.4 }}
          css={styles.root}
        >
          <div>{header}</div>

          <div>{component}</div>
        </motion.div>
      </div>
      <div css={styles.mobileReaction}>
        <MobileReaction />
      </div>
    </div>
  );
};

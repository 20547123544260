import React from 'react';
import { Button, Heading, Logo, Paragraph } from 'modules/app';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { talentOnboardingAction } from 'modules/onboarding';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';

export const TalentReaction: React.FC = () => {
  const dispatch = useDispatch();

  function onFinish() {
    dispatch(
      talentOnboardingAction.updateTalentOnboardingAccess({
        finishedScreen: true,
      }),
    );
    navigate('/app/onboarding-talent-final');
  }

  return (
    <div css={styles.root}>
      <div>
        <Logo />

        <motion.div
          css={styles.headerWrapper}
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.4 }}
        >
          <div css={styles.headerGroup}>
            <Heading as="h1" size="fluidXxxLarge">
              <FormattedMessage id="onboarding.dynamic_headings.expertise.header_0" />
              <br />
              <FormattedMessage id="onboarding.dynamic_headings.expertise.header_1" />
            </Heading>
          </div>

          <div css={styles.contentGroup}>
            <Paragraph size="fluidMedium">
              <FormattedMessage id="onboarding.dynamic_headings.expertise.content_0" />
            </Paragraph>

            <Paragraph size="fluidMedium">
              <FormattedMessage id="onboarding.dynamic_headings.expertise.content_1" />
            </Paragraph>
          </div>
        </motion.div>
      </div>

      <div css={styles.buttonGroup}>
        <Button buttonType="secondary" onClick={onFinish}>
          <FormattedMessage id="onboarding.expertise.btn" />
        </Button>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, CheckboxField, Pill } from 'modules/app';
import * as styles from 'modules/register/styles/Forms.styles';
import * as ghostRegisterStyles from 'modules/register/styles/GhostRegister.styles';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby-plugin-intl';
import { useParams } from '@reach/router';
import { FullPasswordField } from 'modules/app/components/form/FullPasswordField';
import { useAuth } from 'modules/auth';

interface FormData {
  password: string;
  confirmPassword: string;
  isTosAccepted: boolean;
}

export const GhostRegisterForm: React.FC = () => {
  const {
    handleSubmit,
    formState,
    formState: { errors },
    ...methods
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
      isTosAccepted: false,
    },
  });
  const params = useParams();
  const { error, ghostRegister } = useAuth();

  const [isInRequest, setIsInRequest] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);

  const onValid = async (data: FormData) => {
    if (!params || typeof params.token !== 'string') return;

    setIsInRequest(true);
    await ghostRegister(data, params.token);
    setIsInRequest(false);
  };

  return (
    <FormProvider {...{ ...methods, handleSubmit, formState, errors }}>
      <form onSubmit={handleSubmit(onValid)}>
        {error && error.status === 404 && (
          <Pill
            type="error"
            css={ghostRegisterStyles.error}
            title="register.ghostRegister.tokenNotFoundTitle"
            text="register.ghostRegister.tokenNotFoundText"
          />
        )}

        {error && error.cause === 'registration_invalid_email' && (
          <Pill
            css={ghostRegisterStyles.error}
            type="error"
            title="login.page.account.link_text"
            text="http_errors.invalid_credentials"
          />
        )}

        {error && error.cause === 'token_action_executed' && (
          <Pill
            css={ghostRegisterStyles.error}
            type="error"
            title="login.page.account.link_text"
            text="http_errors.invalid_credentials"
          />
        )}

        <FullPasswordField
          passwordLabel="register.password_label"
          confirmPasswordLabel="register.confirm_password_label"
        />

        <CheckboxField
          constraints={{
            required: true,
          }}
          checked={isTosAccepted}
          name="isTosAccepted"
          customStyles={ghostRegisterStyles.checkbox}
          onChecked={(checked) => setIsTosAccepted(checked)}
        >
          <FormattedMessage
            id="register.page.tos_accept.main"
            values={{
              link: (
                <Link to="/privacy-policy">
                  <FormattedMessage id="register.page.tos_accept.privacy_link" />
                </Link>
              ),
            }}
          />
        </CheckboxField>

        <div>
          <Button
            buttonType="secondary"
            css={[styles.button, ghostRegisterStyles.button]}
            textId="register.page.create_account_title"
            disabled={isInRequest || !isTosAccepted}
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
};

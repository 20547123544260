import { flexCenter } from 'style/global.styles';
import { css } from '@emotion/react';
import { root as verificationRoot } from 'modules/register/verification/styles/EmailVerifying.styles';

export const root = css`
  min-height: 100vh;
  color: white;
  overflow: hidden;
  background-color: hsl(var(--color-text-1));
  ${flexCenter};
  padding: calc(var(--unit) * 5);
`;

export const component = css`
  ${verificationRoot};
`;

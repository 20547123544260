import React, { useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { AuthBackground } from 'modules/app/components/stepMechanism';
import { StepOne } from './steps';
import { FinalStep } from './steps/FinalStep';
import { Grid } from 'modules/app';
import { gridConfig } from './styles/Root.styles';
import { NotFound } from 'modules/notFound';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';

export const Root: React.FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<Step>('step-one');
  const { formatMessage } = useIntl();

  const onChangeStep = (step: Step) => setStep(step);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.resetPassword' })} />

      <Grid customCss={gridConfig} type="pageTwoColsReverse">
        <AuthBackground step={step} type="resetPassword" isRight />
        <Router>
          <StepOne onChangeStep={onChangeStep} path=":token" />
          <FinalStep path="complete" />
          <NotFound additionalStyles="sideWrapper" type="contentOnly" default />
        </Router>
      </Grid>
    </>
  );
};

import React, { useEffect } from 'react';
import { useLocation, useParams } from '@reach/router';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { SignUp } from './SignUp';
import Seo from 'modules/app/components/Seo';

export const Root: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const providers = ['google', 'github', 'linkedin'];

    if (!providers.includes(params.provider)) {
      navigate('/login');

      return;
    }
  }, [params]);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.login' })} />

      <div>
        <SignUp
          code={new URLSearchParams(location.search).get('code')}
          provider={params.provider}
        />
      </div>
    </>
  );
};

import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as styles from './styles/Final.styles';
import { Button, Heading, Logo, Paragraph } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import Seo from 'modules/app/components/Seo';
import { useOnboarding, useOnboardingDone } from './hooks';

export const CompanyFinal: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();
  const isFinishedScreen = useSelector(
    (state: AppState) => state.companyOnboarding.finishedScreen,
  );
  const companyHandle = useSelector(
    (state: AppState) => state.companyOnboarding.companyData?.handle,
  );
  const { isLoading, signalCompanyOnboarding } = useOnboarding();

  useOnboardingDone(companyHandle);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.companyOnboardingFinal' })} />

      {isFinishedScreen && (
        <div css={styles.root}>
          <div css={styles.inner}>
            <Logo />

            <div css={styles.content}>
              <Heading as="h1" size="fluidHuge">
                <FormattedMessage id="onboarding.form.fields.company_final.header.0" />
                <br />
                <FormattedMessage id="onboarding.form.fields.company_final.header.1" />
              </Heading>

              <Paragraph size="fluidLarge">
                <FormattedMessage id="onboarding.form.fields.company_final.subtitle" />
              </Paragraph>

              <Button
                buttonType="secondary"
                onClick={signalCompanyOnboarding}
                isLoading={isLoading}
              >
                <FormattedMessage id="onboarding.form.fields.company_final.button" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

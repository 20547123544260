import React from 'react';
import * as styles from 'modules/onboarding/styles/Breadcrumbs.styles';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import ArrowRight from 'assets/icons/arrowRight.svg';

interface Props {
  step: CompanyOnboardingStep;
  steps: CompanyOnboardingStep[];
}

function isInformation(s: string): boolean {
  return s.search(/information/) !== -1;
}

export const CompanyBreadcrumbs: React.FC<Props> = ({ step }) => {
  const breadcrumbAccess = useSelector(
    (state: AppState) => state.companyOnboarding,
  );

  const crumbs = ['company', 'information/basics', 'expertise'];

  const links = crumbs.map((crumb, i) => (
    <React.Fragment key={i}>
      <a
        css={[
          styles.link,
          step == crumb || (isInformation(step) && isInformation(crumb))
            ? styles.highlightLink
            : undefined,
        ]}
        onClick={() => {
          if (crumb === 'company') {
            navigate(`/app/onboarding`);

            return;
          }

          if (!breadcrumbAccess[crumb]) {
            return;
          }

          navigate(`/app/onboarding/company/${crumb}`);
        }}
      >
        <FormattedMessage id={`onboarding.breadcrumbs.${crumb}`} />
      </a>

      {i !== crumbs.length - 1 && <ArrowRight key={i + 1} />}
    </React.Fragment>
  ));

  return <div css={styles.root}>{links}</div>;
};

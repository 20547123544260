import { useCsrfCookie, useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export const useResetPassword = () => {
  const http = useHttpClient('/auth');
  const { isLoggedIn } = useCsrfCookie();

  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSending, setIsSending] = useState(isLoggedIn());

  async function requestResetPassword(email: string) {
    setIsLoading(true);
    try {
      await http.post<{ email: string }>('/reset', { email });
      setIsDisabled(true);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.code === 'chill_out') {
        setIsDisabled(true);
        return;
      }
      setError(e);
    } finally {
      setIsSending(false);
      setIsLoading(false);
    }
  }

  async function resetPassword(
    token: string,
    password: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.post<{ password: string }>(`/reset/${token}`, { password });
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    isSending,
    isDisabled,
    setIsDisabled,
    resetPassword,
    requestResetPassword,
  };
};

import { css } from '@emotion/react';

export const root = css`
  margin-top: calc(var(--unit) * 40);
  color: hsl(var(--color-primary-1));
`;

export const mainTitle = css`
  margin-bottom: calc(var(--unit) * 15);
`;

export const title = css`
  font-size: var(--font-size-xxlarge);
  margin-bottom: calc(var(--unit) * 20);

  p:first-of-type {
    margin-top: calc(var(--unit));
    font-family: var(--font-family-secondary);
    font-weight: normal;
  }
`;

export const subtitle = css`
  font-size: var(--font-size-large);
  line-height: calc(var(--unit) * 6);
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 15) 0;
  font-family: var(--font-family-primary);
`;

export const info = css`
  font-size: var(--font-size-base);
  line-height: calc(var(--unit) * 6);
  margin-bottom: calc(var(--unit) * 15);
  font-family: var(--font-family-primary);
`;

export const errorFix = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;
`;

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, EmailField, emailRegex, useCsrfCookie } from 'modules/app';
import { Link } from 'gatsby-plugin-intl';
import * as styles from '../styles/Form.styles';
import * as formStyles from 'modules/register/styles/Forms.styles';
import { FormattedMessage } from 'react-intl';

interface FormData {
  email: string;
}

interface Props {
  onValid: (email: string) => void;
  isInRequest: boolean;
}

export const FormOne: React.FC<Props> = ({ onValid, isInRequest }) => {
  const methods = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  });
  const { isLoggedIn } = useCsrfCookie();

  const { handleSubmit } = methods;

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data: FormData) => onValid(data.email))}>
          <EmailField
            value=""
            constraints={{
              required: {
                value: true,
                message: 'register.errors.email_required',
              },
              pattern: {
                value: emailRegex,
                message: 'register.errors.email_invalid',
              },
            }}
          />

          <div css={styles.formButtonWrapper}>
            <Button
              buttonType="secondary"
              css={formStyles.button}
              textId="forgot_password.page.forgot_password_label"
              disabled={isInRequest}
              type="submit"
            />

            {!isLoggedIn() && (
              <Link to="/register">
                <FormattedMessage id="login.page.account.link_text" />
              </Link>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

import React from 'react';
import * as styles from 'modules/onboarding/styles/Header.styles';
import { CompanyBreadcrumbs } from './CompanyBreadcrumbs';

interface Props {
  step: CompanyOnboardingStep;
}

export const CompanyHeader: React.FC<Props> = ({ step }) => {
  const steps: CompanyOnboardingStep[] = ['information/basics', 'expertise'];

  return (
    <div css={styles.root}>
      <CompanyBreadcrumbs step={step} steps={steps} />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import * as styles from 'modules/onboarding/styles/Step.styles';
import * as stepStyles from 'modules/onboarding/styles/StepRoot.styles';
import * as reactionStyles from 'modules/onboarding/styles/DynamicReaction.styles';
import { CompanyHeader } from './CompanyHeader';
import { CompanyReaction } from './CompanyReaction';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { CompanyMobileReaction } from './CompanyMobileReaction';

interface Props extends RouteComponentProps {
  header: React.ReactNode;
  component: React.ReactNode;
  name: CompanyOnboardingStep;
  onSubmit?: () => void;
}

export const CompanyStep: React.FC<Props> = ({
  header,
  component,
  name,
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] =
    useState<CompanyOnboardingStep>('information/basics');

  useEffect(() => {
    setCurrentStep(name);
  }, [name]);

  return (
    <div css={stepStyles.root}>
      <div css={reactionStyles.root}>
        <CompanyReaction onSubmit={onSubmit} step={name} />
      </div>
      <div css={stepStyles.formsContent}>
        <CompanyHeader step={currentStep} />
        <motion.div
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.4 }}
          css={styles.root}
        >
          <div>{header}</div>

          <div>{component}</div>
        </motion.div>
      </div>
      <div css={styles.mobileReaction}>
        <CompanyMobileReaction step={name} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

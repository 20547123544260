import React, { useEffect, useState } from 'react';
import { useAuthDispatch, useHttpClient, HttpError } from 'modules/app';
import { loginAction } from 'modules/login';

import * as styles from './styles/Root.styles';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';
import { registrationActions } from '../register';
import Loading from 'assets/icons/loading.svg';
import { motion } from 'framer-motion';
import { HttpError as Error } from 'modules/app/http/client/httpError';

interface Props {
  code: string | null;
  provider: string;
}

export const SignUp: React.FC<Props> = ({ code, provider }) => {
  const [isInRequest, setIsInRequest] = useState(true);
  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();
  const { dispatchLogin } = useAuthDispatch();

  const http = useHttpClient('/auth');

  async function socialLogin() {
    if (!code) return;

    setIsInRequest(true);
    try {
      const {
        data: { user, userSession, userStaffRole, userCompanyRoles },
      } = await http.post<SessionResponse, { authorizationCode: string }>(
        `/oauth2/${provider}`,
        { authorizationCode: code },
      );
      dispatch(loginAction.reset());
      dispatchLogin(user, userSession, userStaffRole, userCompanyRoles);
      if (!user.firstName || !user.lastName || !user.dob) {
        dispatch(registrationActions.reset());
        dispatch(registrationActions.startSocialRegistrationProcess());
        navigate('/app/social-register/social-step-one');
        return;
      }

      // make a http call to settings to check if social data collection is needed
      navigate('/app/onboarding');
    } catch (e) {
      if (!(e instanceof Error)) return;
      setError(e);
    } finally {
      setIsInRequest(false);
    }
  }

  useEffect(() => {
    if (!code) return;

    socialLogin();
  }, [code, provider]);

  return (
    <div css={styles.root}>
      <div css={styles.component}>
        {isInRequest && !error && (
          <>
            <motion.div
              animate={{
                rotateZ: [0, 360],
              }}
              transition={{ duration: 0.8, repeat: Infinity, ease: 'linear' }}
            >
              <Loading />
            </motion.div>

            <div>
              <h1>
                <FormattedMessage id="shared.social.social_sign_up" />
              </h1>
            </div>
          </>
        )}

        {error && <HttpError error={error} />}
      </div>
    </div>
  );
};

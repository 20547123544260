import React, { useEffect } from 'react';
import * as styles from 'modules/onboarding/styles/Expertise.styles';
import { TalentExpertiseForm } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useTalentExpertise, removeWhiteSpace } from 'modules/talents';

export const Expertise: React.FC = () => {
  const {
    chosenExpertise,
    totalExpertise,
    setTotalExpertise,
    setChosenExpertise,
    getExpertise,
    deleteExpertise,
  } = useTalentExpertise();

  useEffect(() => {
    if (chosenExpertise) return;
    getExpertise();
  }, [chosenExpertise]);

  return (
    <div css={styles.root}>
      <TalentExpertiseForm
        setChosenExpertise={setChosenExpertise}
        totalExpertise={totalExpertise}
        setTotalExpertise={setTotalExpertise}
      />

      <div css={styles.chosen}>
        {chosenExpertise && chosenExpertise.length !== 0 && (
          <p css={styles.label}>
            <FormattedMessage id="onboarding.form.fields.expertise.your_chosen_expertise" />
          </p>
        )}

        <div css={styles.chosenExpertise}>
          <ul css={styles.chosenList}>
            {chosenExpertise &&
              chosenExpertise.map((expertise: Expertise) => (
                <li css={styles.chosenListItem} key={expertise.id}>
                  <p css={styles.chosenText}>
                    #{removeWhiteSpace(expertise.name)}
                  </p>
                  <button
                    onClick={() => deleteExpertise(expertise.userTagId)}
                    css={styles.chosenRemove}
                  >
                    <div css={styles.minus} />
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

import { FormattedMessage } from 'gatsby-plugin-intl';
import { Button, Counter, Heading } from 'modules/app';
import React from 'react';

import * as styles from '../styles/Freeze.styles';

interface Props {
  isDisabled: boolean;
  isLoading: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
  onReset: () => Promise<void>;
}

export const ResendEmail: React.FC<Props> = ({
  isDisabled,
  isLoading,
  setIsDisabled,
  onReset,
}) => {
  return (
    <div>
      <Heading as="h2" size="fluidXxLarge">
        <FormattedMessage id="freeze.resendTitle" />
      </Heading>
      <p css={styles.resendSubtitle}>
        <FormattedMessage id="freeze.resendSubtitle" />
      </p>
      <p css={styles.resendContent}>
        <FormattedMessage id="freeze.resendContent" />
      </p>

      <Button
        buttonType="secondary"
        onClick={onReset}
        disabled={isLoading || isDisabled}
        isLoading={isLoading}
      >
        <FormattedMessage
          id={isDisabled ? 'freeze.resendIn' : 'freeze.resendBtn'}
          values={{
            counter: (
              <Counter
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                duration={60}
              />
            ),
          }}
        />
      </Button>
    </div>
  );
};

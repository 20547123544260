import { css } from '@emotion/react';

export const button = css`
  margin-top: calc(var(--unit) * 10);
`;

export const checkbox = css`
  margin: calc(var(--unit) * 10) 0 calc(var(--unit) * 10) 0;
`;

export const error = css`
  margin-bottom: calc(var(--unit) * 10);
`;

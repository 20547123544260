import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  width: 100%;
  color: white;
  max-width: 640px;
  display: flex;
  align-items: flex-start;

  h1 {
    ${font.size.xLarge};
    ${font.family.secondary};
    font-weight: normal;
    margin: 0;
  }

  & > div {
    margin-right: calc(var(--unit) * 5);

    svg {
      height: calc(var(--unit) * 6);
      width: calc(var(--unit) * 6);
    }
  }

  @media ${breakpoints.large} {
    h1 {
      ${font.size.huge};
    }

    & > div {
      margin-right: calc(var(--unit) * 10);

      svg {
        height: calc(var(--unit) * 15);
        width: calc(var(--unit) * 15);
      }
    }
  }
`;

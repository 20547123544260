import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, EmailField, emailRegex } from 'modules/app';
import * as styles from 'modules/register/styles/Forms.styles';
import * as formStyles from './styles/Form.styles';

interface FormData {
  email: string;
}

interface Props {
  onValid: (email: string) => void;
  isInRequest: boolean;
}

export const Form: React.FC<Props> = ({ onValid, isInRequest }) => {
  const methods = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = methods;

  return (
    <div>
      <FormProvider {...methods}>
        <form
          css={formStyles.root}
          onSubmit={handleSubmit((data: FormData) => onValid(data.email))}
        >
          <EmailField
            value=""
            theme="dark"
            constraints={{
              required: {
                value: true,
                message: 'register.errors.email_required',
              },
              pattern: {
                value: emailRegex,
                message: 'register.errors.email_invalid',
              },
            }}
          />

          <Button
            buttonType="secondary"
            textId="shared.verification.resend_label"
            css={styles.button}
            disabled={isInRequest}
            type="submit"
          />
        </form>
      </FormProvider>
    </div>
  );
};

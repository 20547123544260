import { css } from '@emotion/react';
import { flexCenter } from 'style/global.styles';

export const root = css`
  min-height: 100vh;
  overflow: hidden;
  background-color: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
  display: flex;
  flex-direction: column;
`;

export const component = css`
  flex-grow: 1;
  ${flexCenter};
  padding: calc(var(--unit) * 5);
  overflow: hidden;
`;

export const logo = css`
  margin: calc(var(--unit) * 10) 0 0 calc(var(--unit) * 10);
`;

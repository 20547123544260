import { FormattedMessage } from 'react-intl';
import React from 'react';

import * as styles from './styles/EmailVerifying.styles';
import Loading from 'assets/icons/loading.svg';
import { motion } from 'framer-motion';

interface Props {
  isInRequest: boolean;
  isSuccess: boolean;
}

export const EmailVerifying: React.FC<Props> = ({ isInRequest, isSuccess }) => {
  return (
    <div css={styles.root}>
      {isInRequest && (
        <motion.div
          animate={{
            rotateZ: [0, 360],
          }}
          transition={{ duration: 0.8, repeat: Infinity, ease: 'linear' }}
        >
          <Loading />
        </motion.div>
      )}

      <div>
        <h1>
          <FormattedMessage
            id={
              isSuccess
                ? 'shared.verification.in_progress_text'
                : 'shared.verification.in_progress_header'
            }
          />
        </h1>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import * as styles from './styles/LinkExpired.styles';
import { FormattedMessage } from 'react-intl';
import { Alert, Heading, HttpError } from 'modules/app';
import { useAuth } from 'modules/auth';

import { Form } from './Form';

export const LinkExpired: React.FC = () => {
  const { error, resendVerificationEmail } = useAuth();
  const [isInRequest, setIsInRequest] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onValid = async (email: string) => {
    setIsInRequest(true);
    const response = await resendVerificationEmail(email);
    if (!response?.success) return;

    setIsSuccess(response.success);
    setIsInRequest(false);
  };

  return (
    <div css={styles.root}>
      <Heading as="h1" size="huge">
        <FormattedMessage id="shared.verification.link_expired_title" />
      </Heading>

      <Heading as="h2" size="large">
        <FormattedMessage id="shared.verification.link_expired_subtitle" />
      </Heading>

      {!error && !isSuccess && (
        <Form isInRequest={isInRequest} onValid={onValid} />
      )}

      <div>
        <Alert
          styles={styles.alert}
          message={<FormattedMessage id="shared.verification.resend_success" />}
          type="success"
          show={isSuccess}
        />

        <HttpError error={error} styles={styles.alert} />
      </div>
    </div>
  );
};

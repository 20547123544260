import React from 'react';
import { Button, Heading, Paragraph } from 'modules/app';
import * as finalStepStyle from '../styles/FinalStep.styles';
import { FormattedMessage } from 'react-intl';
import * as formStyles from 'modules/register/styles/Forms.styles';
import { navigate } from 'gatsby-plugin-intl';

export const FinalStepBox: React.FC = () => {
  return (
    <div css={finalStepStyle.root}>
      <div css={finalStepStyle.title}>
        <Heading as="h1" size="fluidXxLarge">
          <FormattedMessage id="reset_password.page.final_step_title" />
        </Heading>
      </div>

      <Paragraph size="fluidLarge" css={finalStepStyle.subtitle}>
        <FormattedMessage id="reset_password.page.final_step_subtitle" />
      </Paragraph>

      <Paragraph size="base" css={finalStepStyle.info}>
        <FormattedMessage id="reset_password.page.final_step_content" />
      </Paragraph>

      <Button
        buttonType="secondary"
        onClick={() => navigate('/login')}
        css={formStyles.button}
        textId="reset_password.page.final_screen_btn"
      />
    </div>
  );
};

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, HttpError } from 'modules/app';
import { Link } from 'gatsby-plugin-intl';
import * as rootStyles from '../styles/Root.styles';
import * as styles from 'modules/register/styles/Forms.styles';
import { FormattedMessage } from 'react-intl';
import { FullPasswordField } from 'modules/app/components/form/FullPasswordField';

export interface FormData {
  password: string;
  confirmPassword: string;
}

interface Props {
  onValid: (data: FormData) => void;
  isInRequest: boolean;
  httpError?: ApiErrorData;
}

export const FormOne: React.FC<Props> = ({
  onValid,
  isInRequest,
  httpError,
}) => {
  const { handleSubmit, ...methods } = useForm<FormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <div>
      <FormProvider {...{ ...methods, handleSubmit }}>
        <form onSubmit={handleSubmit(onValid)}>
          <FullPasswordField />

          <HttpError error={httpError} />

          <div css={rootStyles.formButtonWrapper}>
            <Button
              buttonType="secondary"
              css={styles.button}
              textId="reset_password.page.reset_password_label"
              disabled={isInRequest}
              type="submit"
            />

            <Link to="/login">
              <FormattedMessage id="reset_password.page.back_btn" />
            </Link>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

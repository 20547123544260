import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as styles from '../styles/LinkExecuted.styles';
import { Button, Heading, Paragraph } from 'modules/app';
import { navigate } from 'gatsby-plugin-intl';

export const LinkExecuted: React.FC = () => {
  return (
    <div css={styles.root}>
      <Heading as="h1" size="fluidXHuge">
        <FormattedMessage id="shared.verification.link_activated.header" />
      </Heading>

      <div css={styles.paragraphSeparator}>
        <Paragraph size="fluidLarge" fontFamily="primary">
          <FormattedMessage id="shared.verification.link_activated.paragraph" />
        </Paragraph>
      </div>

      <Button buttonType="secondary" onClick={() => navigate('/login')}>
        <FormattedMessage id="shared.verification.link_activated.btn" />
      </Button>
    </div>
  );
};

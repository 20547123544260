import React, { useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import { Heading } from 'modules/app';
import {
  CompanyStep,
  CompanyBasics,
  CompanyExpertise,
  Content,
} from './components';
import { NotFound } from 'modules/notFound';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';

export const CompanyRoot: React.FC<RouteComponentProps> = () => {
  const [currentForm, setCurrentForm] = useState<HTMLFormElement>();
  const { formatMessage } = useIntl();

  const onSubmit = () => {
    currentForm?.dispatchEvent(
      new Event('submit', {
        cancelable: true,
      }),
    );
  };

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.companyOnboarding' })} />

      <Router>
        <CompanyStep
          onSubmit={onSubmit}
          path="information/basics"
          name="information/basics"
          header={
            <Heading as="h1" size="fluidLarge">
              <FormattedMessage id="onboarding.headings.information.0" />
              <br />
              <FormattedMessage id="onboarding.headings.information.1" />
            </Heading>
          }
          component={
            <Content>
              <CompanyBasics
                onInit={(form: HTMLFormElement) => setCurrentForm(form)}
              />
            </Content>
          }
        />

        <CompanyStep
          path="expertise"
          name="expertise"
          header={
            <Heading as="h1" size="fluidLarge">
              <FormattedMessage id="onboarding.headings.company_expertise.0" />
            </Heading>
          }
          component={
            <Content>
              <CompanyExpertise />
            </Content>
          }
        />

        <NotFound additionalStyles="sideWrapper" type="contentOnly" default />
      </Router>
    </>
  );
};

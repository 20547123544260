import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { FormattedMessage } from 'react-intl';

import { Heading } from 'modules/app';
import { Step, Expertise } from './components';
import { NotFound } from 'modules/notFound';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';

export const TalentRoot: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.talentOnboarding' })} />

      <Router>
        <Step
          path="expertise"
          header={
            <Heading as="h1" size="fluidLarge">
              <FormattedMessage id="onboarding.headings.expertise.0" />
            </Heading>
          }
          component={<Expertise />}
        />

        <NotFound additionalStyles="sideWrapper" type="contentOnly" default />
      </Router>
    </>
  );
};

import React, { useEffect } from 'react';
import {
  BackBtn,
  StepRouterComponent,
} from 'modules/app/components/stepMechanism';

import {
  ButtonGroup,
  ContentWithDeco,
  HttpError,
  Loading,
  useCsrfCookie,
} from 'modules/app';
import { FinalStepBox } from './FinalStepBox';
import { navigate } from 'gatsby-plugin-intl';
import * as styles from 'modules/register/styles/Steps.styles';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';

import * as rootStyles from '../styles/Root.styles';
import { useResetPassword } from '../hooks';

interface Props extends StepRouterComponent {
  email: string | undefined;
}

export const FinalStep: React.FC<Props> = ({ onChangeStep, email }) => {
  const { isLoggedIn } = useCsrfCookie();

  const { error, isSending, requestResetPassword } = useResetPassword();

  useEffect(() => {
    onChangeStep('step-two');
  }, [onChangeStep]);

  useEffect(() => {
    if (!email) {
      navigate('/app/forgot-password');
    }
  }, [email]);

  const onLoggedIn = async () => {
    if (!email) {
      navigate('/app/forgot-password');
      return;
    }

    if (!isLoggedIn()) return;

    await requestResetPassword(email);
  };

  useEffect(() => {
    onLoggedIn();
  }, [email]);

  return (
    <ContentWithDeco css={[styles.root, rootStyles.content]}>
      <ButtonGroup css={styles.accountLink}>
        <BackBtn
          onBack={() => {
            onChangeStep('step-one');
            navigate(
              isLoggedIn()
                ? '/profile/talent/overview/invitations'
                : '/app/forgot-password',
            );
          }}
        />
      </ButtonGroup>
      <motion.div
        transition={{ delay: 0.1, duration: 0.4 }}
        style={{ opacity: 0 }}
        {...FADE_IN_MOVE_Y_REGULAR}
      >
        <Loading
          isLoading={isSending}
          component={
            !error ? (
              <FinalStepBox email={email || ''} />
            ) : (
              <HttpError error={error} />
            )
          }
        />
      </motion.div>
    </ContentWithDeco>
  );
};

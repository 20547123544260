import { RouteComponentProps } from '@reach/router';
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl';
import { Button, HttpError, Logo, MainContent } from 'modules/app';
import { useFreezeAccount } from 'modules/auth';
import React from 'react';
import { ResendEmail } from './components';

import * as styles from './styles/Freeze.styles';

interface Props extends RouteComponentProps {
  token?: string;
}

export const Freeze: React.FC<Props> = ({ token }) => {
  const { formatMessage } = useIntl();
  const {
    error,
    isFrozen,
    isLoading,
    isDisabled,
    isResending,
    isPasswordReset,
    freezeAccount,
    resetPassword,
    setIsDisabled,
  } = useFreezeAccount();

  const onFreeze = () => {
    if (!token) return;

    freezeAccount(token);
  };

  return (
    <section css={styles.root}>
      <MainContent customStyles={styles.wrapper}>
        <nav>
          <Logo />
        </nav>
        <div css={styles.content}>
          {isFrozen ? (
            <div>
              <p css={styles.text}>
                <FormattedMessage
                  id="freeze.success"
                  values={{
                    reset: formatMessage({ id: 'freeze.reset' }),
                  }}
                />
              </p>
              <Link css={styles.resetBtn} to="/app/forgot-password">
                <FormattedMessage id="freeze.resetBtn" />
              </Link>
            </div>
          ) : isPasswordReset ? (
            <ResendEmail
              isDisabled={isDisabled}
              isLoading={isResending}
              setIsDisabled={setIsDisabled}
              onReset={resetPassword}
            />
          ) : (
            <div>
              <p css={styles.text}>
                <FormattedMessage id="freeze.content0" />
              </p>
              <p css={styles.text}>
                <FormattedMessage id="freeze.content1" />
              </p>
              <Button
                buttonType="secondary"
                isLoading={isLoading}
                onClick={onFreeze}
                css={styles.btn}
              >
                <FormattedMessage id="freeze.freezeBtn" />
              </Button>

              {error && <HttpError error={error} styles={styles.btn} />}
            </div>
          )}
        </div>
      </MainContent>
    </section>
  );
};

import React from 'react';
import { Button, Heading, Logo, Paragraph } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useCompanyReaction } from 'modules/onboarding';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';

interface Props {
  onSubmit?: () => void;
  step: CompanyOnboardingStep;
}

export const CompanyReaction: React.FC<Props> = ({ onSubmit, step }) => {
  const { onReactionSubmit, getListTitle } = useCompanyReaction(step, onSubmit);
  const { isInRequest } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );
  const { companyData } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );

  const title = getListTitle();
  const isLastStep = step === 'expertise';
  const isLoading = step === 'information/basics' && isInRequest;

  const buttonText = (): string => {
    if (!Boolean(step === 'information/basics')) {
      if (isLastStep) {
        return 'onboarding.form.finish_button';
      }

      return 'onboarding.form.next_step_up';
    }

    if (companyData) {
      return 'onboarding.form.updateCompanyAndContinue';
    }

    return 'onboarding.form.createCompanyAndContinue';
  };

  return (
    <div css={styles.root}>
      <div>
        <Logo />

        <motion.div
          css={styles.headerWrapper}
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.4 }}
        >
          <div css={styles.headerGroup}>
            <Heading as="h1" size="fluidXxxLarge">
              <FormattedMessage id={title} />
            </Heading>
          </div>

          {step === 'expertise' && (
            <div css={styles.contentGroup}>
              <Paragraph size="fluidMedium">
                <FormattedMessage id="onboarding.dynamic_headings.company_expertise.content_0" />
              </Paragraph>

              <Paragraph size="fluidMedium">
                <FormattedMessage id="onboarding.dynamic_headings.company_expertise.content_1" />
              </Paragraph>
            </div>
          )}
        </motion.div>
      </div>

      <div css={styles.buttonGroup}>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          buttonType="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onReactionSubmit();
          }}
        >
          <FormattedMessage id={buttonText()} />
        </Button>

        {step !== 'information/basics' && !isLastStep && (
          <Button
            buttonType="ghost"
            css={styles.skipForNow}
            onClick={onReactionSubmit}
          >
            <FormattedMessage id="onboarding.form.skip_for_now" />
          </Button>
        )}
      </div>
    </div>
  );
};

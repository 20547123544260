import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  @media ${breakpoints.medium} {
    margin: calc(var(--unit) * 14) auto calc(var(--unit) * 10) auto;
    width: 50%;
  }

  input[type='text'] {
    color: white;
  }

  cursor: pointer;
`;

export const clipboard = css`
  margin-top: calc(var(--unit) * 8);
`;

import React from 'react';
import { FinalStepBox } from './FinalStepBox';
import { RouteComponentProps } from '@reach/router';
import { ContentWithDeco } from 'modules/app';

import * as styles from 'modules/register/styles/Steps.styles';
import * as rootStyles from '../styles/Root.styles';

export const FinalStep: React.FC<RouteComponentProps> = () => {
  return (
    <ContentWithDeco css={[styles.root, rootStyles.content]}>
      <FinalStepBox />
    </ContentWithDeco>
  );
};

import React, { useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { Grid } from 'modules/app';
import { AuthBackground } from 'modules/app/components/stepMechanism';
import { StepOne } from './steps';
import { FinalStep } from './steps/FinalStep';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { gridColConfig } from './styles/Root.styles';
import { NotFound } from 'modules/notFound';
import Seo from 'modules/app/components/Seo';

export const Root: React.FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<Step>('step-one');
  const [email, setEmail] = useState<string>();
  const { formatMessage } = useIntl();

  const onChangeStep = (step: Step) => setStep(step);

  const onEmailGiven = (email: string) => {
    setEmail(email);

    navigate('/app/forgot-password/sent');
  };

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.forgotPassword' })} />

      <Grid customCss={gridColConfig} type="pageTwoColsReverse">
        <AuthBackground step={step} type="forgotPassword" isRight />
        <Router>
          <StepOne
            onEmailGiven={onEmailGiven}
            onChangeStep={onChangeStep}
            path="/"
          />
          <FinalStep email={email} onChangeStep={onChangeStep} path="sent" />
          <NotFound additionalStyles="sideWrapper" type="contentOnly" default />
        </Router>
      </Grid>
    </>
  );
};

import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const gridConfig = css`
  & > section:first-of-type {
    order: 2;
  }
`;

export const formButtonWrapper = css`
  margin-top: calc(var(--unit) * 10);

  @media ${breakpoints.large} {
    display: flex;
    justify-content: space-between;
  }

  & button {
    width: 100%;
    display: block;

    @media ${breakpoints.large} {
      max-width: 50%;
    }
  }

  a {
    text-align: center;
    display: block;
    color: hsl(var(--color-text-1));
    font-family: var(--font-family-primaryBold);
    font-weight: 700;
    margin-top: calc(var(--unit) * 8);
    text-decoration: underline;

    ${breakpoints.large} {
      max-width: 50%;
    }

    :hover {
      border-bottom: 1px solid hsl(var(--color-primary-1));
    }
  }
`;

export const content = css`
  max-width: 816px;

  @media ${breakpoints.large} {
    padding: ${spacing(50)} ${spacing(80)};
    margin-left: auto;
    margin-right: 0;
  }
`;

export const heading = css`
  margin: ${spacing(66)} 0 ${spacing(40)} 0;

  @media ${breakpoints.medium} {
    margin: ${spacing(66)} 0 ${spacing(40)} 0;
  }
`;

export const alertFix = css`
  margin-bottom: calc(var(--unit) * 10);
`;

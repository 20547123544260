import React from 'react';
import * as styles from 'modules/onboarding/styles/Breadcrumbs.styles';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-plugin-intl';

import ArrowRight from 'assets/icons/arrowRight.svg';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  step: OnboardingStep;
  steps: OnboardingStep[];
}

function isEducation(s: string): boolean {
  return s.search(/education/) !== -1;
}

function isExperience(s: string): boolean {
  return s.search(/experience/) !== -1;
}

export const Breadcrumbs: React.FC<Props> = ({ step }) => {
  const breadcrumbAccess = useSelector(
    (state: AppState) => state.talentOnboarding,
  );

  const crumbs = ['talent', 'expertise'];

  const links = crumbs.map((crumb, i) => (
    <React.Fragment key={i}>
      <a
        css={[
          styles.link,
          step == crumb ||
          (isEducation(step) && isEducation(crumb)) ||
          (isExperience(step) && isExperience(crumb))
            ? styles.highlightLink
            : undefined,
        ]}
        onClick={() => {
          if (crumb === 'talent') {
            navigate(`/app/onboarding`);

            return;
          }

          if (!breadcrumbAccess[crumb]) {
            return;
          }

          navigate(`/app/onboarding/talent/${crumb}`);
        }}
      >
        <FormattedMessage id={`onboarding.breadcrumbs.${crumb}`} />
      </a>

      {i !== crumbs.length - 1 && <ArrowRight key={i + 1} />}
    </React.Fragment>
  ));

  return <div css={styles.root}>{links}</div>;
};

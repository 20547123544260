import React, { useEffect } from 'react';
import { Button, Counter, Heading, HttpError, Paragraph } from 'modules/app';
import * as rootStyles from '../styles/Root.styles';
import { FormattedMessage } from 'react-intl';
import * as formStyles from 'modules/register/styles/Forms.styles';
import { navigate } from 'gatsby-plugin-intl';
import { useResetPassword } from '../hooks';

interface Props {
  email: string;
}

export const FinalStepBox: React.FC<Props> = ({ email }) => {
  const { error, isLoading, isDisabled, setIsDisabled, requestResetPassword } =
    useResetPassword();

  useEffect(() => {
    if (!email) {
      navigate('/app/forgot-password');
    }
  }, [email]);

  const onResendEmail = async () => {
    if (isDisabled) return;

    requestResetPassword(email);
  };

  return (
    <div css={rootStyles.root}>
      <div css={rootStyles.title}>
        <Heading as="h1" size="xxLarge">
          <FormattedMessage id="forgot_password.page.final_step.title" />
        </Heading>
      </div>

      <Paragraph size="large" css={rootStyles.subtitle}>
        <FormattedMessage id="forgot_password.page.final_step.subtitle" />
      </Paragraph>

      <Paragraph size="base" css={rootStyles.info}>
        <FormattedMessage id="forgot_password.page.final_step.content" />
      </Paragraph>

      <Button
        buttonType="secondary"
        onClick={onResendEmail}
        css={formStyles.button}
        disabled={isLoading || isDisabled}
        isLoading={isLoading}
      >
        <FormattedMessage
          id={
            isDisabled
              ? 'forgot_password.page.final_step.resend'
              : 'shared.verification.resend_label'
          }
          values={{
            counter: (
              <Counter
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                duration={60}
              />
            ),
          }}
        />
      </Button>

      {error && error.status !== 404 && (
        <HttpError error={error} styles={rootStyles.errorFix} />
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { AuthBackground } from 'modules/app/components/stepMechanism';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { Grid, useCsrfCookie } from 'modules/app';

import { SocialStepOne } from './SocialStepOne';
import { SocialStepTwo } from './SocialStepTwo';
import { useSelector } from 'react-redux';
import { AppState } from '../redux-store';
import Seo from 'modules/app/components/Seo';

export const Root: React.FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<Step>('social-step-one');
  const { isLoggedIn } = useCsrfCookie();
  const registration = useSelector((state: AppState) => state.registration);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/login');

      return;
    }

    if (!registration.isSocialRegistrationProcess) {
      navigate('/register');
    }
  }, [isLoggedIn, registration.isSocialRegistrationProcess]);

  const onChangeStep = (step: Step) => setStep(step);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.register' })} />

      <Grid type="pageTwoCols">
        <AuthBackground step={step} type="register" />

        <Router>
          <SocialStepOne onChangeStep={onChangeStep} path="social-step-one" />
          <SocialStepTwo onChangeStep={onChangeStep} path="social-step-two" />
        </Router>
      </Grid>
    </>
  );
};

import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import * as headingStyles from 'modules/app/styles/Heading.styles';
import { linkUnderline } from 'style/global.styles';

export const root = css`
  min-height: 100vh;
  overflow: hidden;
  background-color: hsl(var(--color-grayscale-9));
  color: white;
`;

export const inner = css`
  margin: 0 auto;
  max-width: 1600px;
  padding: ${spacing(32)};

  @media ${breakpoints.xxLarge} {
    padding: ${spacing(80)} ${spacing(144)};
  }
`;

export const content = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 528px;
  padding: 32px;

  p {
    font-family: var(--font-family-primary);
    margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 10) 0;
  }

  @media ${breakpoints.xxLarge} {
    padding: 0;
  }
`;

export const profileEdit = css`
  background-color: transparent;
  color: white;
  padding: 0;
  font-family: var(--font-family-primary);
  text-decoration: underline;
  ${headingStyles.fluidLarge};

  &:hover {
    background-color: transparent;
  }
`;

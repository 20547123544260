import React from 'react';
import { Loading, CompanyForm, HttpError } from 'modules/app';
import { useCompanyBreadcrumbAccess } from 'modules/onboarding/hooks/useCompanyBreadcrumbAccess';
import { useGetCompanyData } from 'modules/onboarding';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  onInit: (form: HTMLFormElement) => void;
}

export const CompanyBasics: React.FC<Props> = ({ onInit }) => {
  useCompanyBreadcrumbAccess('information/basics');

  const { countries, companyData, isLoading, error } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );

  useGetCompanyData();

  return (
    <Loading
      isLoading={isLoading}
      component={
        <>
          {countries && !error ? (
            <CompanyForm
              onInit={onInit}
              company={companyData}
              isOnboarding={true}
              countriesValue={countries}
            />
          ) : (
            <HttpError error={error} />
          )}
        </>
      }
    />
  );
};

import { RouteComponentProps } from '@reach/router';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { Button, Heading, Logo } from 'modules/app';
import React, { useState } from 'react';

import CheckedIcon from 'assets/icons/check.svg';
import * as styles from '../styles/ChooseAccount.styles';
import { StaticImage } from 'gatsby-plugin-image';

type SelectedType = 'talent' | 'company';

export const ChooseAccount: React.FC<RouteComponentProps> = () => {
  const [selected, setSelected] = useState<SelectedType>();

  function onSelect(type: SelectedType) {
    if (selected === type) {
      setSelected(undefined);
      return;
    }
    setSelected(type);
  }

  function onCreate() {
    if (!selected) return;

    if (selected === 'talent') {
      navigate('/app/onboarding/talent/expertise');
      return;
    }

    navigate('/app/onboarding/company/information/basics');
  }

  return (
    <section css={styles.root}>
      <div css={styles.content}>
        <div css={styles.info}>
          <div>
            <Logo />
            <Heading as="h1" size="fluidHuge" css={styles.title}>
              <FormattedMessage id="onboarding.choose.title" />
            </Heading>
          </div>
          <p css={styles.welcomeText}>
            <FormattedMessage id="onboarding.choose.committee" />
          </p>
        </div>
        <div css={styles.choose}>
          <Heading as="h3" size="fluidXxLarge">
            <FormattedMessage id="onboarding.choose.type" />
          </Heading>
          <p css={styles.chooseContent}>
            <FormattedMessage id="onboarding.choose.typeSubtitle" />
          </p>

          <div>
            <input
              css={styles.input}
              type="checkbox"
              onChange={() => onSelect('talent')}
              id="talent"
            />
            <label
              htmlFor="talent"
              css={selected === 'talent' ? styles.labelChecked : styles.label}
            >
              <div css={styles.image}>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  src="../../../assets/images/talent-choose.png"
                  alt="Company icons"
                />
                <div css={styles.imageContent}>
                  <p css={styles.inputTitle}>
                    <FormattedMessage id="onboarding.choose.talent" />
                  </p>
                  <p css={styles.inputContent}>
                    <FormattedMessage id="onboarding.choose.talentContent" />
                  </p>
                </div>
              </div>
              <span
                css={
                  selected === 'talent' ? styles.controlChecked : styles.control
                }
              >
                {selected === 'talent' && <CheckedIcon />}
              </span>
            </label>

            <input
              css={styles.input}
              type="checkbox"
              id="company"
              onChange={() => onSelect('company')}
            />

            <label
              htmlFor="company"
              css={selected === 'company' ? styles.labelChecked : styles.label}
            >
              <div css={styles.image}>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  src="../../../assets/images/company-choose.png"
                  alt="Company icons"
                />
                <div css={styles.imageContent}>
                  <p css={styles.inputTitle}>
                    <FormattedMessage id="onboarding.choose.company" />
                  </p>
                  <p css={styles.inputContent}>
                    <FormattedMessage id="onboarding.choose.companyContent" />
                  </p>
                </div>
              </div>
              <span
                css={
                  selected === 'company'
                    ? styles.controlChecked
                    : styles.control
                }
              >
                {selected === 'company' && <CheckedIcon />}
              </span>
            </label>
          </div>
          <Button
            buttonType="secondary"
            onClick={onCreate}
            disabled={!selected}
            css={styles.btn}
          >
            <FormattedMessage id="onboarding.choose.btn" />
          </Button>
        </div>
      </div>
    </section>
  );
};

import React from 'react';
import {
  BackBtn,
  StepRouterComponent,
} from 'modules/app/components/stepMechanism';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-plugin-intl';

import {
  ButtonGroup,
  ContentWithDeco,
  Heading,
  SocialLogin,
  useCsrfCookie,
} from 'modules/app';
import { FormOne } from '../forms';
import * as styles from 'modules/register/styles/Steps.styles';
import * as rootStyles from '../styles/Root.styles';

import { useIsLoggedIn, useResetPassword } from '../hooks';

interface Props extends StepRouterComponent {
  onEmailGiven: (email: string) => void;
}

export const StepOne: React.FC<Props> = ({ onEmailGiven }) => {
  const isLogged = useIsLoggedIn(onEmailGiven);

  const { isLoggedIn } = useCsrfCookie();

  const { isLoading, requestResetPassword } = useResetPassword();

  const onValid = async (email: string) => {
    await requestResetPassword(email);

    onEmailGiven(email);
  };

  if (isLogged) {
    return null;
  }

  return (
    <ContentWithDeco
      css={[styles.root, rootStyles.content]}
      footer={
        !isLoggedIn() ? (
          <SocialLogin text="shared.social.social_component_forgot_password" />
        ) : null
      }
    >
      <ButtonGroup css={styles.accountLink}>
        <BackBtn
          onBack={() =>
            navigate(
              isLoggedIn() ? '/profile/talent/overview/invitations' : '/login',
            )
          }
        />
      </ButtonGroup>

      <Heading as="h1" size="xxLarge">
        <FormattedMessage id="forgot_password.page.title.0" />
      </Heading>

      <Heading as="h1" size="xxLarge" css={rootStyles.mainTitle}>
        <FormattedMessage id="forgot_password.page.title.1" />
      </Heading>

      <FormOne onValid={onValid} isInRequest={isLoading} />
    </ContentWithDeco>
  );
};

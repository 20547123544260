import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { useAuthDispatch, useHttpClient } from 'modules/app';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { registrationActions } from '../register/redux';
import { BasicInfo } from '../register/content/BasicInfo';
import { getFormattedDateOfBirth } from 'modules/register/helpers';
import { HttpError } from 'modules/app/http/client/httpError';

export const SocialStepTwo: React.FC<StepRouterComponent> = ({
  onChangeStep,
}) => {
  const registration = useSelector((state: AppState) => state.registration);
  const session = useSelector((state: AppState) => state.session);
  const [httpError, setHttpError] = useState<ApiErrorData>();
  const { dispatchUpdatedUser } = useAuthDispatch();
  const dispatch = useDispatch();
  const positionRef = useRef<HTMLSpanElement>(null);

  const http = useHttpClient('/api/v1/settings');

  useEffect(() => {
    onChangeStep('social-step-two');
  }, [dispatch, onChangeStep]);

  useEffect(() => {
    if (!registration.isTosAccepted) {
      navigate('/app/social-register/social-step-one');

      return;
    }
  }, [registration.isTosAccepted]);

  useEffect(() => {
    if (!registration.isSocialRegistrationProcess) {
      navigate('/register');
    }
  }, [registration]);

  useEffect(() => {
    if (httpError) {
      positionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [httpError]);

  const onNext = async (data: RegistrationData) => {
    try {
      await http.put<SocialBasicInfo>('/user-single-information', {
        firstName: data.name,
        lastName: data.lastName,
        dob: getFormattedDateOfBirth(data.dob),
      });
      dispatch(registrationActions.completeRegistration());
      const updatedUser = {
        ...session.user,
        firstName: data.name,
        lastName: data.lastName,
        dob: getFormattedDateOfBirth(data.dob),
      };
      dispatchUpdatedUser(
        updatedUser,
        session.userCompanyRoles,
        session.userStaffRole,
      );
      navigate('/app/onboarding');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  };

  const onBack = () => {
    onChangeStep('social-step-one');
    navigate('/app/social-register/social-step-one');
  };

  return (
    <>
      <span ref={positionRef} />
      <BasicInfo
        externalFirstName={session.user?.firstName}
        externalLastName={session.user?.lastName}
        onBack={onBack}
        onNext={onNext}
        externalHttpError={httpError}
        isSocialProcess={true}
      />
    </>
  );
};

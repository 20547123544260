import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import Seo from 'modules/app/components/Seo';
import { ProtectedRouter, PublicRouter } from 'modules/app';

import { Root as VerificationRoot } from 'modules/register/verification/Root';
import { Root as ForgotPasswordRoot } from 'modules/forgotPassword';
import { Root as ResetPasswordRoot } from 'modules/resetPassword';
import { Root as OnboardingRoot } from 'modules/onboarding/Root';
import { Root as SocialLoginRoot } from 'modules/socialLogin';
import { Root as SocialRegister } from 'modules/socialRegister';
import { TalentFinal } from '../modules/onboarding/TalentFinal';
import { CompanyFinal } from '../modules/onboarding/CompanyFinal';
import { ClaimCompany, JoinUser } from 'modules/settings/components';
import { NotFound } from 'modules/notFound';
import { GhostRegistrationRoot } from 'modules/register/GhostRegistrationRoot';
import { PaymentCheck } from 'modules/billing/components';
import { CardCheck } from 'modules/billing/components/CardCheck';
import { Freeze } from 'modules/freeze';

const App: React.FC<PageProps> = () => {
  if (typeof window === 'undefined') return null;

  return (
    <>
      <Seo title="Roango" />

      <Router basepath="/:lang/app">
        <PublicRouter
          Component={ForgotPasswordRoot}
          path={`forgot-password/*`}
        />

        <PublicRouter Component={ResetPasswordRoot} path={`reset-password/*`} />

        <PublicRouter Component={VerificationRoot} path="verify/:token" />

        <PublicRouter
          Component={GhostRegistrationRoot}
          path="ghost-register/:token"
        />

        <PublicRouter Component={SocialLoginRoot} path="oauth/:provider" />

        <SocialRegister path="social-register/*" />

        <ProtectedRouter
          path={`onboarding-talent-final`}
          Component={TalentFinal}
        />
        <ProtectedRouter
          path={`onboarding-company-final`}
          Component={CompanyFinal}
        />

        <ProtectedRouter
          Component={JoinUser}
          path={`company/users/join/:companyInviteToken`}
        />

        <ProtectedRouter
          Component={ClaimCompany}
          path={`transfer/:companyClaimToken`}
        />

        <ProtectedRouter path={`onboarding/*`} Component={OnboardingRoot} />

        <ProtectedRouter
          path={`payment-check/:companyId`}
          Component={PaymentCheck}
        />

        <ProtectedRouter path={`card-check/:companyId`} Component={CardCheck} />

        <Freeze path="freeze-account/:token" />

        <NotFound default type="contentOnlyCenter" />
      </Router>
    </>
  );
};

export default App;

import { FormError } from 'modules/app/intl';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PasswordField } from './PasswordField';

interface Props {
  passwordLabel?: string;
  confirmPasswordLabel?: string;
}

export const FullPasswordField: React.FC<Props> = ({
  passwordLabel,
  confirmPasswordLabel,
}) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const password = watch('password', '');

  return (
    <div>
      <PasswordField
        name="password"
        label={passwordLabel || 'reset_password.page.inputs.password'}
      />
      <PasswordField
        name="confirm_password"
        label={confirmPasswordLabel || 'reset_password.page.inputs.repeat'}
        validations={(value) => {
          return (
            value === password || 'reset_password.page.password_do_not_match'
          );
        }}
      />

      {errors?.confirm_password?.type === 'validate' && (
        <FormError errors={errors} name="confirm_password" />
      )}
    </div>
  );
};

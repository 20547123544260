import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  color: hsl(var(--color-primary-1));

  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 40);
  }
`;

export const gridColConfig = css`
  & > section:first-of-type {
    order: 2;
  }
`;

export const content = css`
  max-width: 816px;

  @media ${breakpoints.large} {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const mainTitle = css`
  margin-bottom: calc(var(--unit) * 15);
`;

export const title = css`
  font-size: var(--font-size-xxlarge);
  margin-bottom: calc(var(--unit) * 30);

  p:first-of-type {
    margin-top: calc(var(--unit));
    font-family: var(--font-family-secondary);
    font-weight: normal;
  }
`;

export const subtitle = css`
  font-size: var(--font-size-large);
  line-height: calc(var(--unit) * 6);
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;
  font-family: var(--font-family-primary);
`;

export const info = css`
  font-size: var(--font-size-base);
  line-height: calc(var(--unit) * 6);
  margin-bottom: calc(var(--unit) * 8);
  font-family: var(--font-family-primary);
`;

export const errorFix = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;
`;

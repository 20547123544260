import { FormattedMessage } from 'gatsby-plugin-intl';
import { Button } from 'modules/app';
import React from 'react';

import * as styles from 'modules/onboarding/styles/MobileReaction.styles';
import { useCompanyReaction } from 'modules/onboarding';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  onSubmit?: () => void;
  step: CompanyOnboardingStep;
}

export const CompanyMobileReaction: React.FC<Props> = ({ onSubmit, step }) => {
  const { onReactionSubmit } = useCompanyReaction(step, onSubmit);
  const { isInRequest } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );

  const isLastStep = step === 'expertise';
  const isLoading = step === 'information/basics' && isInRequest;

  return (
    <div css={styles.root}>
      <motion.div {...FADE_IN_MOVE_Y_REGULAR} transition={{ duration: 0.4 }}>
        <div css={styles.buttons}>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            buttonType="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              onReactionSubmit();
            }}
          >
            <FormattedMessage id="onboarding.form.next_step_up" />
          </Button>

          {step !== 'information/basics' && !isLastStep && (
            <Button
              buttonType="ghost"
              css={styles.skipBtn}
              onClick={onReactionSubmit}
            >
              <FormattedMessage id="onboarding.form.skip_for_now" />
            </Button>
          )}
        </div>
      </motion.div>
    </div>
  );
};

import React, { useEffect } from 'react';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { navigate } from 'gatsby-plugin-intl';

import { registrationActions } from '../register/redux';
import { useStep } from '../register/steps/useStep';
import { Terms } from '../register/content/Terms';

export const SocialStepOne: React.FC<StepRouterComponent> = ({
  onChangeStep,
}) => {
  const registration = useSelector((state: AppState) => state.registration);

  const onNextStep = useStep(
    onChangeStep,
    'social-step-two',
    'social-step-one',
    registrationActions.addStepTwo,
  );

  useEffect(() => {
    if (!registration.isSocialRegistrationProcess) {
      navigate('/register');
    }
  }, [registration]);

  const onNext = (data: RegistrationData) => {
    onNextStep(data, false);
    navigate('/app/social-register/social-step-two');
  };

  return <Terms onNext={onNext} isSocialProcess={true} />;
};

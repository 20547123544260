import { css } from '@emotion/react';

export const root = css`
  width: 100%;
  padding: calc(var(--unit) * 15) calc(var(--unit) * 8);
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
`;

export const buttons = css`
  width: 100%;
  max-width: 568px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  button {
    width: 100%;
  }
`;

export const skipBtn = css`
  margin-top: calc(var(--unit) * 5);
  color: hsl(var(--color-grayscale-1));
  padding: 0;
  text-decoration: underline;
`;

export const title = css`
  margin-bottom: calc(var(--unit) * 15);
  color: hsl(var(--color-grayscale-1));
`;

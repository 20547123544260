import React, { useEffect } from 'react';
import {
  Loading,
  HttpError,
  CompanyExpertiseForm,
  CompanyExpertiseList,
} from 'modules/app';
import { useCompanyBreadcrumbAccess } from 'modules/onboarding/hooks/useCompanyBreadcrumbAccess';

import * as styles from 'modules/onboarding/styles/Expertise.styles';
import { useCompanyEdit } from 'modules/profile/hooks';
import { useCompanyId } from 'modules/onboarding/hooks';

export const CompanyExpertise: React.FC = () => {
  const isAvailable = useCompanyBreadcrumbAccess('expertise');
  const companyId = useCompanyId();

  const { error, isLoading, expertise, getExpertise, removeExpertise } =
    useCompanyEdit();

  function onAddExpertise(companyId: string) {
    getExpertise(companyId, false);
  }

  async function deleteExpertise(expertiseId: string) {
    if (!companyId) return;
    removeExpertise(companyId, expertiseId);
  }

  useEffect(() => {
    if (!companyId || !isAvailable) return;

    getExpertise(companyId);
  }, [companyId, isAvailable]);

  return (
    <div css={styles.root}>
      <CompanyExpertiseForm
        totalExpertise={expertise?.total || 0}
        callback={onAddExpertise}
        companyId={companyId}
      />

      <Loading
        isLoading={isLoading}
        altText="register.alt_tags.verification_loading"
        component={
          error ? (
            <HttpError error={error} />
          ) : (
            <CompanyExpertiseList
              onRemove={deleteExpertise}
              expertiseList={expertise?.data}
            />
          )
        }
      />
    </div>
  );
};

import { useCsrfCookie } from 'modules/app';
import { AppState } from 'modules/redux-store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useIsLoggedIn = (onEmail: (email: string) => void) => {
  const { isLoggedIn } = useCsrfCookie();
  const { user } = useSelector((state: AppState) => state.session);
  const isLogged = user && isLoggedIn();

  useEffect(() => {
    if (!isLogged) {
      return;
    }

    onEmail(user.email);
  }, []);

  return isLogged;
};

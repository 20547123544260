import { css } from '@emotion/react';
import { scrollbar } from 'style/global.styles';

export const root = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;

  margin-top: calc(var(--unit) * 4);

  ${scrollbar};

  & > a {
    cursor: pointer;
    font-size: var(--font-size-base);

    :hover {
      transition: color 0.5s;
      color: hsl(var(--color-text-1));
    }
  }

  & > svg {
    margin: 0 calc(var(--unit) * 4) 0 calc(var(--unit) * 4);
    min-width: 12px;
  }
`;

export const link = css`
  color: hsl(var(--color-textLight-7));
`;

export const highlightLink = css`
  color: hsl(var(--color-text-1));
`;

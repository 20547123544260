import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';

import { TalentRoot } from './TalentRoot';
import { CompanyRoot } from './CompanyRoot';

import { useOnboardingDone } from './hooks';
import { NotFound } from 'modules/notFound';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';
import { ChooseAccount } from './components';

export const Root: React.FC<RouteComponentProps> = () => {
  const isOnboardingDone = useOnboardingDone();
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.onboarding' })} />

      {!isOnboardingDone && (
        <div>
          <Router>
            <ChooseAccount path="/" />

            <TalentRoot path="/talent/*" />

            <CompanyRoot path="/company/*" />

            <NotFound default />
          </Router>
        </div>
      )}
    </>
  );
};
